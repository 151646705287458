import timg1 from '../images/resource/speaker-1.jpg'
import timg2 from '../images/resource/speaker-2.jpg'
import timg3 from '../images/resource/speaker-3.jpg'
import timg4 from '../images/resource/speaker-4.jpg'
import timg5 from '../images/resource/speaker-5.jpg'
import timg6 from '../images/resource/speaker-6.jpg'
import timg7 from '../images/resource/speaker-7.jpg'
import timg8 from '../images/resource/speaker-8.jpg'

import tsimg1 from '../images/resource/team-1.jpg'
import tsimg2 from '../images/resource/team-2.jpg'
import tsimg3 from '../images/resource/team-3.jpg'
import tsimg4 from '../images/resource/team-4.jpg'
import tsimg5 from '../images/resource/team-5.jpg'
import tsimg6 from '../images/resource/team-6.jpg'
import tsimg7 from '../images/resource/team-15.jpg'
import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Patrick Spencer',
      slug: 'Patrick-Spencer',
      title: 'S&P Analyzer',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Angelina Holy',
      slug: 'Angelina-Holy',
      title: 'Maxiis Manager',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Matthew White',
      slug: 'Regina-Blackly',
      title: 'Facebooks Co-Founder',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Michael Dover',
      slug: 'Michael-Dover',
      title: 'Starbucks CEO',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Janet Jones',
      slug: 'Janet-Jones',
      title: 'Newyork Posts GM',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Jane Hamilton',
      slug: 'Jane-Hamilton',
      title: 'Starbucks CEO',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Jenson Button',
      slug: 'Jenson-Button',
      title: 'Softwere Manager',
   },
   {
      Id: '8',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Harnold Min',
      slug: 'Harnold-Min',
      title: 'Starbucks CEO',
   },

]

export default Teams;