import React from 'react';
import { Link } from 'react-router-dom'
import blogtp from '../../images/resource/news-17.jpg'
import blogtp2 from '../../images/resource/author-5.jpg'
import blog1 from '../../images/resource/author-1.jpg'
import blog2 from '../../images/resource/author-2.jpg'
import blog3 from '../../images/resource/author-3.jpg'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    const submitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="content-side col-lg-8 col-md-12 col-sm-12">
                        <div className="blog-detail">
                            <div className="blog-detail_inner">
                                <div className="blog-detail_image">
                                    <img src={BlogDetails.blogSingleImg} alt="" />
                                </div>
                                <div className="blog-detail_content">
                                    <div className="blog-detail_date">2 May 2023</div>
                                    <ul className="blog-detail_meta">
                                        <li><span className="icon fa fa-regular fa-calendar"></span>by Christine</li>
                                        <li><span className="icon fa fa-solid fa-comments"></span>(03) Comments</li>
                                    </ul>
                                    <h3 className="blog-detail_heading">{BlogDetails.title}</h3>
                                    <p>Single stroke at the present moment and yet I feel that lorem quis bibendum auctor. Elitm sed eiusmod tempor incididunt umst etsu dolore magna aliquatenim ad.</p>
                                    <p>Sed quia conse quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore eamagna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex commodo consequat. </p>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                                    <p>randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                    <blockquote>
                                        It's crazy, but the things you love, prospective buyers might hate and that means you might have a hard time unloading your home when it comes time to sell. Here's how to choose wisely
                                        <span className="blockquote_author">Leo Marken</span>
                                        <span className="blockquote_quote flaticon-quote"></span>
                                    </blockquote>
                                    <div className="two-column">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="news-block_one-image-two">
                                                    <img src={blogtp} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <p>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. </p>
                                                <p>The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. </p>
                                    <div className="post-share-options">
                                        <div className="post-share-inner d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="tags"><span>Tags:</span><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Conference,</Link> <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Event,</Link> <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Seminar</Link></div>
                                            <ul className="social-box">
                                                <span>Share:</span>
                                                <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">facebook</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">twitter</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">linkedin</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">pinterest</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-author-box">
                                    <div className="author-inner">
                                        <div className="thumb"><img src={blogtp2} alt=""/></div>
                                        <div className="name">Lean Guimares <span>CEO CHI ART PRO</span></div>
                                        <div className="text">Vestibulum imperdiet nibh vel magna lacinia ultrices. Sed id interdum urna. Nam ac elit a ante commodo tristique. Duis lacus urna, condimen tum a vehiculaa.</div>
                                        <ul className="social-box">
                                            <li><a rel='noopener noreferrer' href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
                                            <li><a rel='noopener noreferrer' href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
                                            <li><a rel='noopener noreferrer' href="https://dribbble.com/" className="fa fa-dribbble"></a></li>
                                            <li><a rel='noopener noreferrer' href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="comment-login_box">
                                    <h4 className="comment-login_title">Comments</h4>
                                    <div className="comment-login_box-inner">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="comment-login_box-text">Join the conversation</div>
                                            <ul className="comment-login_box-nav">
                                                <li><Link onClick={ClickHandler} to="/register">Login</Link></li>
                                                <li><Link onClick={ClickHandler} to="/register">Signup</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="comments-area">
                                    <div className="group-title">
                                        <h4>(03) Comments</h4>
                                    </div>
                                    <div className="comments-content">
                                        <div className="comment-box">
                                            <div className="comment">
                                                <div className="author-thumb"><img src={blog1} alt=""/></div>
                                                <div className="comment-inner">
                                                    <ul className="comment-options">
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Share</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Flag</Link></li>
                                                    </ul>
                                                    <div className="comment-info"><strong>Lionalo Messiko</strong><div className="comment-time"> 1 Day Ago</div></div>
                                                    <div className="text">Fully agree, as long as all precautions are taken like the facemask and distancing in the photo. Bookshops are among the most vital British and that's not just English - and Western institutions, intellectually and recreationally.</div>
                                                    <ul className="likes-option">
                                                        <li><span className="fa fa-thumbs-up"></span>10</li>
                                                        <li><span className="fa fa-thumbs-down"></span>1</li>
                                                    </ul>
                                                    <Link onClick={ClickHandler} className="comment-reply" to="/"><span className="fa fa-share"></span> Reply</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comment-box reply-comment">
                                            <div className="comment">
                                                <div className="author-thumb"><img src={blog2} alt=""/></div>
                                                <div className="comment-inner">
                                                    <ul className="comment-options">
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Share</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Flag</Link></li>
                                                    </ul>
                                                    <div className="comment-info"><strong>Ronaldoino</strong><div className="comment-time"> 1 Day Ago</div></div>
                                                    <div className="text">Agency is the ability to act autonomously and freely, and in psychology the term is often used to refer to people who feel that they are able to act independently and effectively to control their own lives.</div>
                                                    <ul className="likes-option">
                                                        <li><span className="fa fa-thumbs-up"></span>10</li>
                                                        <li><span className="fa fa-thumbs-down"></span>1</li>
                                                    </ul>
                                                    <Link onClick={ClickHandler} className="comment-reply" to="/"><span className="fa fa-share"></span> Reply</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="comments-content">
                                        <div className="comment-box">
                                            <div className="comment">
                                                <div className="author-thumb"><img src={blog3} alt=""/></div>
                                                <div className="comment-inner">
                                                    <ul className="comment-options">
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Share</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Flag</Link></li>
                                                    </ul>
                                                    <div className="comment-info"><strong>Kilonia.</strong><div className="comment-time"> 1 Day Ago</div></div>
                                                    <div className="text">Fully agree, as long as all precautions are taken like the facemask and distancing in the photo. Bookshops are among the most vital British and that's not just English - and Western institutions, intellectually and recreationally.</div>
                                                    <ul className="likes-option">
                                                        <li><span className="fa fa-thumbs-up"></span>10</li>
                                                        <li><span className="fa fa-thumbs-down"></span>1</li>
                                                    </ul>
                                                    <Link onClick={ClickHandler} className="comment-reply" to="/"><span className="fa fa-share"></span> Reply</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="comment-form">
                                    <div className="group-title">
                                        <h4>Leave a reply here</h4>
                                    </div>
                                    <form onSubmit={submitHandler}>
                                        <div className="row clearfix">

                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="username" placeholder="Your Name" required="" />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="username" placeholder="Email Address" required="" />
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <textarea className="" name="message" placeholder="Your Message here..."></textarea>
                                            </div>

                                            <div className="col-lg-12 text-center col-md-12 col-sm-12 form-group">
                                                <div className="button-box">
                                                    <button className="btn-style-two theme-btn">
                                                        <div className="btn-wrap">
                                                            <span className="text-one">Post comment</span>
                                                            <span className="text-two">Post comment</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                    <BlogSidebar />

                </div>
            </div>
        </div>
    )

}

export default BlogSingle;
