import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage2 from '../../components/Contactpage2/Contactpage2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const ContactPage2 =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'} pageTitle2={'Contact Us'}/> 
            <Contactpage2/>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage2;

