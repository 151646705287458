import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Networking',
            des: 'Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Learning',
            des: 'Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor.',
            icon: 'flaticon-book'
        },
        {
            title: 'Spark New ideas',
            des: 'Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor.',
            icon: 'flaticon-idea'
        }
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-one">
            <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div>
            <div className="auto-container">
                <div className="sec-title centered title-anim">
                    <div className="sec-title_title">Join The Event</div>
                    <h2 className="sec-title_heading">Why Attend Conference</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner">
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{service.title}</Link></h4>
                                <div className="service-block_one-text">{service.des}</div>
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="service-block_one-arrow fi flaticon-next-2"></Link>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;