import React from 'react';
import ContactForm from '../ContactFrom'
import { Link } from 'react-router-dom'

const Contactpage2 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="contact-page-section">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="info-column col-lg-3 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-placeholder"></span>
                                    <h6>Address</h6>
                                    <div className="text">Clarkson Ave, Brooklyn, NewYark 11203</div>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-email-1"></span>
                                    <h6>Email</h6>
                                    <a href="mailto:demo@conat.com">demo@conat.com</a><br />
                                    <a href="mailto:demo@conat.com">demo@conat.com</a>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-telephone"></span>
                                    <h6>Phone</h6>
                                    <a href="tel:+000-999-0000">000-999-0000</a><br />
                                    <a href="tel:+999-000-9999-00">+999 000 9999 00</a>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-internet"></span>
                                    <h6>Social media</h6>
                                    <ul className="social-box">
                                        <Link onClick={ClickHandler} to="/" className="facebook fa fa-facebook"></Link>
                                        <Link onClick={ClickHandler} to="/" className="youtube fi flaticon-youtube"></Link>
                                        <Link onClick={ClickHandler} to="/" className="twitter fa fa-twitter"></Link>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="form-column col-lg-9 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <div className="sec-title_title">CONTACT INFO</div>
                                <h2 className="sec-title_heading">Get Meeting With us.</h2>
                            </div>
                            <div className="default-form contact-form">
                                <ContactForm />
                            </div>
                            <div className="map-boxed">
                                <div className="map-outer">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6331292129!2d144.49266890254142!3d-37.97123689954809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2s!4v1574408946759!5m2!1sen!2s" allowFullScreen=""></iframe>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )

}

export default Contactpage2;
