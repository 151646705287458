import React from 'react'
import CountUp from 'react-countup';
import bg from '../../images/background/1.jpg'

const FunFact = (props) => {

    const funFact = [
        {
            title: '124',
            subTitle: 'Speakers',
            Symbol: '+',
        },
        {
            title: '1140',
            subTitle: 'Attendence',
            Symbol: '',
        },
        {
            title: '8',
            subTitle: 'Workshops',
            Symbol: 'HR',
        },
        {
            title: '205',
            subTitle: 'Guests',
            Symbol: '+',
        },


    ]

    return (

        <section className="counter-one">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="counter-one_image" style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="row clearfix">
                        {funFact.map((funfact, fitem) => (
                            <div className="counter-one_block col-lg-3 col-md-6 col-sm-12" key={fitem}>
                                <div className="counter-one_inner">
                                    <div className="counter-one_counter"><h4><CountUp end={funfact.title} enableScrollSpy />{funfact.Symbol}</h4></div>
                                    <div className="counter-one_text">{funfact.subTitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>

    )
}

export default FunFact;