import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import EventsSection from '../../components/EventsSection/EventsSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const EventsPage = (props) => {

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Events'} pagesub={'Events'} pageTitle2={'Events'}/>
            <EventsSection />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventsPage;
