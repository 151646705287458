import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="main-footer">
            <div className="auto-container">
                <div className="widgets-section">
                    <div className="row clearfix">
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="footer-column">
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="logo"><Link onClick={ClickHandler} to="/"><img src={Logo} alt="" title="" /></Link></div>
                                    <div className="footer-social_list">
                                        <Link onClick={ClickHandler} to="/" className="facebook fa fa-facebook"></Link>
                                        <Link onClick={ClickHandler} to="/" className="youtube fi flaticon-youtube"></Link>
                                        <Link onClick={ClickHandler} to="/" className="twitter fa fa-twitter"></Link>
                                    </div>
                                </div>
                                <div className="footer-text">We have very good strength in innovative technology and tools with over 35 years of experience. We makes long-term investments goal in global companies in different sectors.</div>
                            </div>
                        </div>
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="footer-column">
                                <div className="row clearfix">
                                    <div className="footer-info-column col-lg-4 col-md-4 col-sm-12">
                                        <span className="footer-info-icon flaticon-placeholder-1"></span>
                                        <h6 className="footer-info_title">ADDRESS</h6>
                                        <div className="footer-info_text"> 27 New Division Street, New Yark, USA</div>
                                    </div>
                                    <div className="footer-info-column col-lg-4 col-md-4 col-sm-12">
                                        <span className="footer-info-icon flaticon-message"></span>
                                        <h6 className="footer-info_title">MAIL TO US</h6>
                                        <div className="footer-info_text">example@gmail.com <br/>	(+000)111-00000</div>
                                    </div>
                                    <div className="footer-info-column col-lg-4 col-md-4 col-sm-12">
                                        <span className="footer-info-icon flaticon-clock"></span>
                                        <h6 className="footer-info_title">TIMEING</h6>
                                        <div className="footer-info_text">Mon - Fri 09am - 06pm Sunday is off Now.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright">&copy;  Copyright ©2023 noor_tech. All Rights Reserved</div>
                        </div>
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <ul className="footer-bottom-nav">
                                <li><Link onClick={ClickHandler} to="/terms">Terms and conditions</Link></li>
                                <li><Link onClick={ClickHandler} to="/privacy">Privacy policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer;