import React from 'react';
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import blogs from '../../api/blogs'


const BlogList = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className={`content-side col-lg-8 col-md-12 col-sm-12 ${props.blRight}`}>
                        <div className="our-blog">
                            <div className="row clearfix">
                                {blogs.slice(0, 6).map((blog, Bitem) => (
                                    <div className="news-block_one col-lg-6 col-md-6 col-sm-12" key={Bitem}>
                                        <div className="news-block_one-inner">
                                            <div className="news-block_one-tag">{blog.thumb}</div>
                                            <div className="news-block_one-image">
                                                <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}><img src={blog.screens} alt="" /></Link>
                                                <img src={blog.screens} alt="" />
                                            </div>
                                            <div className="news-block_one-content">
                                                <div className="news-block_one-date">{blog.create_at}</div>
                                                <ul className="news-block_one-meta">
                                                    <li><span className="icon fa fa-calendar"></span>by {blog.author}</li>
                                                    <li><span className="icon fa fa-comments"></span>({blog.comment}) Comments</li>
                                                </ul>
                                                <h5 className="news-block_one-title"><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>{blog.title}</Link></h5>
                                                <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`} className="news-block_one-more">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <ul className="styled-pagination text-center">
                                    <li className="prev"><Link onClick={ClickHandler} to="/blog"><span className="fa fa-angle-left"></span> </Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">01</Link></li>
                                    <li className="active"><Link onClick={ClickHandler} to="/blog">02</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">03</Link></li>
                                    <li className="next"><Link onClick={ClickHandler} to="/blog"><span className="fa fa-angle-right"></span> </Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </div>

    )

}

export default BlogList;
